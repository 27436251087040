@import "../../sass/default/color_variable";
.cs-iconbox.cs-style1 {
  padding: 40px;
  border-radius: 10px;
  transition: all 0.4s ease;
  &:hover {
    transform: translateY(-6px);
  }
    background: linear-gradient(180deg, #1c98ff -2.02%, #0d00bf 100%);

  .cs-iconbox_icon {
    padding: 4px 10px;
    display: inline-block;
    background: linear-gradient(90deg, rgba($accent, 0.3) 0%, transparent 95.61%);
  }
  &.cs-type1 {
    .cs-iconbox_icon {
      padding: 0;
      background: transparent;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs-iconbox.cs-style1 {
    padding: 30px;
  }
}